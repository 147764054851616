'use strict';

angular.module('appLogic').directive('book', ['$log', '$timeout', '$sce', 'GenericUtils', function($log, $timeout, $sce, GenericUtils){
  return {
    restrict: 'E',
    templateUrl: 'book/book.html',
    replace: true,
    scope: {
      model: '='
    },
    link: function(scope, element, attrs) {
      var _config = {};
      var _helper = {};
      var link = {
        init: function(){
          GenericUtils.setScope(link.scope, scope);
          GenericUtils.initElement(element);
          $timeout(function(){scope.selectTab(false, 0)});
        },
        scope: {
          selectTab: function(event, index){
            if(event) event.preventDefault();
            if(!scope.model.tabs[index]) return false;
            element.find('.book__tab.js-selected').removeClass('js-selected');
            element.find('.book__tab').eq(index).addClass('js-selected');
            scope.tabContent = $sce.trustAsHtml(scope.model.tabs[index].content);
          }
        }
      };
      link.init();
    }
  }
}]);
