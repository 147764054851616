'use strict';

angular.module('appLogic').directive('anchorLauncher', ['$log', function($log){
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      var _helper = {
        launchModal: function(href, coords){
          var params = href.split(':');
          scope.$emit('launch-modal', {type: params[1], id: params[2], coords: coords})
        },
        launchDictionary: function(href, content, coords){
          var params = href.split(':');
          var term;
          if(params.length > 1) term = params[1];
          else term = content;
          scope.$emit('launch-dictionary', {term: term, coords: coords})
        }
      };
      var link = {
        init: function(){
          link.bindEvents();
        },
        bindEvents: function(){
          element.on('click', 'a', function(e){
            var href = angular.element(e.currentTarget).attr('href');
            var content = angular.element(e.currentTarget).text();
            var cX, cY;
            if(href.indexOf('modal:') !== -1){
              e.preventDefault();
              e.stopPropagation();
              _helper.launchModal(href, [e.clientX, e.clientY]);
            }
            else if(href.indexOf('dict') !== -1){
              e.preventDefault();
              e.stopPropagation();
              _helper.launchDictionary(href, content, [e.clientX, e.clientY]);
            }
          });
        }
      };
      link.init();
    }
  }
}]);
