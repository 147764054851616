'use strict';

angular.module('appLogic').directive('lesson', ['$log', '$sce', '$timeout','GenericUtils', 'AudioService', function($log, $sce, $timeout, GenericUtils, AudioService){
  return {
    restrict: 'E',
    templateUrl: 'lesson/lesson.html',
    replace: true,
    scope: {
      model: '='
    },
    link: function(scope, element, attrs) {
      var _config = {};

      var _els = {
        audioTrack: element.find('.lesson__audio-track-progress'),
        audioTime: element.find('.lesson__audio-time'),
        audioHandle: element.find('.lesson__audio-track-handle')
      }

      var _helper = {

        loadLessonAudio: function(){
          if(!scope.model || !scope.model.audio) return;
          scope.isLoadingAudio = true;
          scope.AudioPlayer.loadAudio(scope.model.audio).then(function(audioObj){
            if(!scope.AudioPlayer) return;
            scope.isLoadingAudio = false;
            scope.AudioPlayer.initAudio(audioObj);
          });
        },

        playAudio: function(){
          if(!scope.AudioPlayer || scope.isLoadingAudio) return false;
          scope.AudioPlayer.play();
          scope.isPlayingAudio = true;
        },

        pauseAudio: function(){
          if(!scope.AudioPlayer || scope.isLoadingAudio) return false;
          scope.AudioPlayer.pause();
          scope.isPlayingAudio = false;
        },

        stopAudio: function(){
          if(!scope.AudioPlayer || scope.isLoadingAudio) return false;
          scope.AudioPlayer.stop();
          scope.isPlayingAudio = false;
        },

        restartAudio: function(){
          if(!scope.AudioPlayer || scope.isLoadingAudio) return false;
          scope.AudioPlayer.restart();
        }

      };


      var link = {


        init: function(){
          GenericUtils.setScope(link.scope, scope);
          GenericUtils.initElement(element);
          link.bindEvents();
          link.initAudioPlayer();
        },

        bindEvents: function(){
          scope.$on('$destroy', function(){
            if(scope.AudioPlayer){
              AudioService.destroyPlayer(scope.AudioPlayer);
              scope.AudioPlayer = null;
            }
          });
        },

        initAudioPlayer: function(){
          if(scope.model && scope.model.audio){
            scope.AudioPlayer = AudioService.makePlayer(_els.audioTime, _els.audioTrack, _els.audioHandle);
            _helper.loadLessonAudio();
          }
        },


        scope: {


          getHtml: function(content){
            return $sce.trustAsHtml(content);
          },


          hasAudio: function(){
            return scope.model && scope.model.audio;
          },


          hasSlideshow: function(){
            return scope.model && scope.model.slideshow;
          },

          toggleAudio: function(){
            if(scope.isPlayingAudio) _helper.pauseAudio();
            else _helper.playAudio();
            scope.audioActive = true;
          },

          hasPlaces: function(){
            return (scope.model.places && scope.model.places.length)
          },

          hasActivities: function(){
            return (scope.model.activities && scope.model.activities.length)
          },

          hasReading: function(){
            return (scope.model.reading && scope.model.reading.length)
          }


        }

      };

      link.init();

    }
  }
}]);
