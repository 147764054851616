'use strict';

angular.module('appLogic').directive('dict', ['$log','$sce', '$timeout','$window','GenericUtils', function($log, $sce, $timeout, $window, GenericUtils){
  return {
    restrict: 'E',
    templateUrl: 'dict/dict.html',
    replace: true,
    scope: {
      dictionary: '=',
      coords: '=',
      term: '@'
    },
    link: function(scope, element, attrs) {
      var _config = {
        boundsPadding: 10,
        yCorrection: 20
      };
      var link = {

        init: function(){

          GenericUtils.setScope(link.scope, scope);
          GenericUtils.initElement(element);

          scope.openedScroll = angular.element($window).scrollTop();

          link.bindEvents();

          $timeout(function(){
            link.correctOriginalPos();
            link.preventOverflow()
          },1);

          $timeout(function(){
            element.addClass('js-init')
          }, 20);

        },
        bindEvents: function(){

          var winEl = angular.element($window);

          winEl.on('scroll.dict', _.throttle(function(){
            var curScroll = winEl.scrollTop();
            if(Math.abs(scope.openedScroll - curScroll) > 30) scope.close()
          }, 100));

          winEl.on('click.dict', function(){
            if(!scope.isHovering) scope.close();
          });

          element.on('mouseenter', function(){
              angular.element('html').addClass('js-lock-scroll');
              scope.isHovering = true;
          });
          element.on('mouseleave', function(){
              angular.element('html').removeClass('js-lock-scroll');
              scope.isHovering = false;
          });

          scope.$on('$destroy', function(){
            winEl.off('scroll.dict');
            winEl.off('click.dict');
          });

        },
        correctOriginalPos: function(){ //Nudge it down a tad for cleaner effect.
          scope.coords[1] = scope.coords[1] + _config.yCorrection;
        },
        preventOverflow: function(){ //Make sure our box doesn't go off the edge of the screen
          var winEl = angular.element($window);
          var xBound = scope.coords[0] + element.width(),
              yBound = scope.coords[1] + element.height(),
              xLimit = winEl.width() - _config.boundsPadding,
              yLimit = winEl.height() - _config.boundsPadding
          if(xBound > xLimit){
            scope.$apply(function(){
              scope.coords[0] = xLimit - element.width();
            });
          }
          if(yBound > yLimit){
            scope.$apply(function(){
              scope.coords[1] = yLimit - element.height();
            });
          }
        },
        scope: {
          getTerm: function(){ //Getting term like this so that we can display formating as its entered within the dictionary JSON
            var dictObj = _.find(scope.dictionary, function(o){ return o.term.toLowerCase() === scope.term.toLowerCase(); });
            return dictObj ? dictObj.term : scope.term;
          },
          getDefinition: function(){
            var dictObj = _.find(scope.dictionary, function(o){ return o.term.toLowerCase() === scope.term.toLowerCase(); });
            return dictObj ? $sce.trustAsHtml(dictObj.definition) : $sce.trustAsHtml('No definition.');
          },
          close: function(){
            element.removeClass('js-init');
            angular.element('html').removeClass('js-lock-scroll');
            $timeout(function(){
              element.remove();
              scope.$destroy();
            }, 600);
          },
        }
      };
      link.init();
    }
  }
}]);
