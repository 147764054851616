'use strict';

angular.module('appLogic').directive('matcher', ['$log', '$sce', '$window', '$timeout','GenericUtils', function($log, $sce, $window, $timeout, GenericUtils){
  return {
    restrict: 'E',
    templateUrl: 'matcher/matcher.html',
    replace: true,
    scope: {
      model: '='
    },
    link: function(scope, element, attrs) {

      var _config = {};

      var _helper = {

        moveStickyHeader: function(tVal){
          var sticky = element.find('.matcher__carousel');
          var tStr = 'translate3d(0px,'+tVal+'px,0px)';
          var ctStr = 'translate(0px,'+tVal+'px,0px)'; //IE9 compatability
          sticky[0].style.webkitTransform = tStr;
          sticky[0].style.MozTransform = tStr;
          sticky[0].style.msTransform = ctStr; //Hooray IE!
          sticky[0].style.OTransform = tStr;
          sticky[0].style.transform = tStr;
        },

        slideTo: function(index){ //Do the actual transform. Animation is set as a transition in CSS
          var sBoard = element.find('.matcher__carousel-items');
          var tVal = scope.slideWidth * index * -1;
          var tStr = 'translate3d('+tVal+'px, 0px, 0px)';
          var ctStr = 'translate('+tVal+'px, 0px)'; //IE9 compatability
          sBoard[0].style.webkitTransform = tStr;
          sBoard[0].style.MozTransform = tStr;
          sBoard[0].style.msTransform = ctStr; //Hooray IE!
          sBoard[0].style.OTransform = tStr;
          sBoard[0].style.transform = tStr;
        },

        setCurrentSlide: function(index){ //this helper method ensures current slide is set within bounds
          if(index >= scope.model.terms.length ){
            scope.currentSlide = 0;
          } else if(index < 0) {
            scope.currentSlide = scope.model.terms.length - 1;
          } else {
            scope.currentSlide = index;
          }
        },

        matchSuccess: function(termId){
          var els = element.find('[term-id="'+termId+'"], [term-match="'+termId+'"], [term-def="'+termId+'"]');
          els.addClass('js-matched');
          $timeout(scope.nextSlide, 600);
          scope.successfulMatches++;
        },

        matchFailure: function(termId){
          var els = element.find('[term-id="'+scope.currentlyMatching+'"], [term-match="'+termId+'"]');
          els.addClass('js-unmatched');
          setTimeout(function(){els.removeClass('js-unmatched')}, 1000);
        }

      };

      var link = {
        init: function(){
          GenericUtils.setScope(link.scope, scope);
          GenericUtils.setScope(link.scope, scope);
          link.setDefaults();
          link.bindEvents();
          $timeout(link.setWidths);
          // $timeout(link.populateDefinitions);
        },

        setDefaults: function(){
          $timeout(function(){scope.currentSlide = 0});
          scope.successfulMatches = 0;
          scope.completed = false;
        },

        bindEvents: function(){

          angular.element($window).on('resize.matcher', _.debounce(function(){
            scope.$apply(function(){
              link.setWidths();
              $timeout(function(){_helper.slideTo(scope.currentSlide)});
            });
          }, 250));

          scope.$watch('currentSlide', function(newVal, oldVal){
            _helper.slideTo(newVal);
            scope.currentlyMatching = angular.element('.matcher__carousel-item').eq(newVal).attr('term-id');
          });

          scope.$watch('currentlyMatching', function(newVal, oldVal){
            element.find('.js-current').removeClass('js-current');
            element.find('[term-match="'+newVal+'"]').addClass('js-current');
          });

          scope.$watch('successfulMatches', function(newVal, oldVal){
            if(scope.successfulMatches >= scope.model.terms.length){
              scope.completed = true;
            } else {
              scope.completed = false;
            }
          });

          element.on('click', '[term-match]', function(e){
            e.preventDefault();
            var thisEl = angular.element(e.currentTarget);
            if(thisEl.hasClass('js-matched')) return;
            var termId = thisEl.attr('term-match');
            if(termId == scope.currentlyMatching){
              _helper.matchSuccess(termId);
            } else {
              _helper.matchFailure(termId);
            }
          });


          //Sticky header within modal hack:
          if(angular.element('.modal').length){
            var toStickyEl = element.find('.matcher__carousel');
            $timeout(function(){scope.stickyTop = toStickyEl.position().top + angular.element($window).height() * 0.1 + 25}, 200);
            angular.element($window).on('resize.matcherSticky', _.debounce(function(){
              $timeout(function(){scope.stickyTop = toStickyEl.position().top + angular.element($window).height() * 0.1 + 25}, 200);
            }, 600));
            angular.element('.modal').on('scroll', _.throttle(function(){
              if(angular.element('.modal').scrollTop() > scope.stickyTop){
                _helper.moveStickyHeader(angular.element('.modal').scrollTop() - scope.stickyTop);
              } else {
                _helper.moveStickyHeader(0);
              }
            }, 1));
          }

          scope.$on('$destroy', function(){
            angular.element($window).off('resize.matcherSticky');
            angular.element($window).off('resize.matcher');
          });

          // element.on('click', '.matcher__map-container', function(e){ //helps make the maps
          //   var cont = angular.element('.matcher__map-container');
          //   var x = ((e.clientX - cont.offset().left) / cont.width()) * 100;
          //   var y = ((e.clientY - cont.offset().top) / cont.height()) * 100;
          //   console.log('left: '+(Math.round(x * 1000) / 1000)+'%; top:'+(Math.round(y * 1000) / 1000)+'%;');
          // });

        },

        setWidths: function(){
          scope.slideWidth = element.find('.matcher__carousel').width(); //styles are set with this in the template on digest.
        },

        populateDefinitions: function(){
          element.find('[term-def]').each(function(){
            var el = $(this);
            var term = _.find(scope.model.terms, function(term){
              return term.id == el.attr('term-def');
            });
            el.html(term.term);
          });
        },

        scope: {

          getContent: function(){
            if(!scope.model || !scope.model.html) return false;
            return $sce.trustAsHtml(scope.model.html);
          },

          nextSlide: function(e){
            if(e) e.preventDefault();
            _helper.setCurrentSlide(scope.currentSlide + 1);
          },

          prevSlide: function(e){
            if(e) e.preventDefault();
            _helper.setCurrentSlide(scope.currentSlide - 1);
          },

          clearAnswers: function(e){
            if(e) e.preventDefault();
            scope.successfulMatches = 0;
            element.find('.js-matched').removeClass('js-matched');
          },

          solveAnswers: function(e){
            if(e) e.preventDefault();
            var els = element.find('[term-id], [term-match], [term-def]');
            els.addClass('js-matched');
            scope.successfulMatches = scope.model.terms.length;
          }

        }
      };
      link.init();
    }
  }
}]);
