'use strict';

angular.module('appLogic').directive('modal', ['$compile','$log', '$timeout','GenericUtils', function($compile, $log, $timeout, GenericUtils){
  return {
    restrict: 'E',
    templateUrl: 'modal/modal.html',
    replace: true,
    scope: {
      type: '@',
      data: '=',
      coords: '=?'
    },
    link: function(scope, element, attrs) {
      var _config = {};
      var link = {
        init: function(){
          GenericUtils.setScope(link.scope, scope);
          GenericUtils.initElement(element);
          angular.element('html').addClass('js-lock-scroll');
          link.bindEvents();
        },
        bindEvents: function(){
          scope.$on('$destroy', function(){
            angular.element('html').removeClass('js-lock-scroll');
          });
        },
        scope: {
          closeModal: function(e){
            element.removeClass('js-init');
            $timeout(function(){
              element.remove();
              scope.$destroy();
            }, 300);
          }
        }
      };
      link.init();
    }
  }
}]);
