'use strict';

angular.module('appLogic').service('DataService', ['$http', '$q', '$log', '$rootScope', '$cacheFactory', function($http, $q, $log, $rootScope, $cacheFactory){




  var _cache = $cacheFactory.get('dataCache');




  var _urls = {
    lessons: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/lessons.json',
    lessonGroupings: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/lesson-groupings.json',
    activities: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/activities.json',
    dictionary: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/dictionary.json',
    slideshows: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/slideshows.json',
    matchMaps: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/match-maps.json',
    matchLists: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/match-lists.json',
    matchers: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/matchers.json',
    videos: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/videos.json',
    books: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/books.json',
    texts: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/texts.json',
    showHide: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/show-hide.json',
    matchersDir: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/matchers/',
    slideshowDir: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/slideshows/',
    lessonAudioDir: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/lesson-audio/',
    videoDir: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/videos/',
    booksDir: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/books/',
    textsDir: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/texts/',
    showHideDir: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/show-hide/'
  }


  var _brandUrls = {
    'odbcu': {
      lessons: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/odbcu/lessons.json',
      lessonGroupings: '//s3-us-west-2.amazonaws.com/lifeofchrist-data/data/odbcu/lesson-groupings.json'
    }
  }



  var _loadTrackers = {}; //we are pre-loading arrays of stuff sometimes and we need to track whats been loaded asynchronously





  var _helper = {





    get: function(url){

      if (!url) return false;
      var deferred = $q.defer();

      $http.get(url)
        .success(function(data){deferred.resolve(data)})
        .error(function(error){deferred.reject(error)});

      return deferred.promise;
    },






    getModelById: function(collection, id){
      return _.find(collection, function(model){
        return model.id === id
      });
    },






    preloadSlideshowFileContents: function(extension, model, slideNum, deferred){ //preload stuff for slideshow then resolve deferred.

      var directory = _urls.slideshowDir + model.folder + '/';

      _helper.get(directory + slideNum + '.' + extension).then(

        function(resData){

          _loadTrackers[extension] = _loadTrackers[extension] || 0; //make sure were not iterating null. Using extension to ensure we dont have crossover on tracking between filetypes.
          model[extension][--slideNum] = resData.replace(/(\r\n|\n|\r)/gm,""); //clean out returns etc.

          if(++_loadTrackers[extension] >= model.slideCount){
            deferred.resolve(model);
            _loadTrackers[extension] = 0;
          }
        }
      );
    },

    buildSlideShowData: function(model, lesson){ //use slideshow json data to generate a more comprehensive model with paths and preloaded data.
      //TODO: Add deferred rejection logic (timeout?)
      //TODO: Do we want to cache preloaded data?

      if(!model.slideCount) throw new Error('No slideCount specified for slideshow: ' + model.id);
      if(!model.folder) throw new Error('No folder specified for slideshow: ' + model.id);

      var deferred = $q.defer();
      var directory = _urls.slideshowDir + model.folder + '/';

      if(lesson){ //if we have a lesson, overwrite stuff.
        model.title = lesson.title || "lesson";
        model.description = lesson.description || null;
      }

      if(model.hasHtml){ //if we have html we are loading just an html file and maybe some audio.

        model.html = [];

        for(var i = 1; i <= model.slideCount; i++){
          if(model.hasAudio) model.audio.push(directory + i + '.mp3'); //just a file reference here no preloading
          _helper.preloadSlideshowFileContents('html', model, i, deferred);
        }

      } else { //if we dont have html then we are loading images and maybe text and audio

        model.images = [];

        if(model.hasAudio) model.audio = [];
        if(model.hasText) model.txt = [];

        for(var i = 1; i <= model.slideCount; i++){

          model.images.push(directory + i + '.jpg'); //just a file reference here no preloading

          if(model.hasAudio) model.audio.push(directory + i + '.mp3'); //just a file reference here no preloading

          if(model.hasText) _helper.preloadSlideshowFileContents('txt', model, i, deferred); //this will resolve the passed deferred when all have been preloaded.
          else deferred.resolve(model); //otherwise resolve right away as there is nothing more to do.

        }

      }

      return deferred.promise;
    },




    preloadBookFileContents: function(model,i, deferred){ //preload stuff for slideshow then resolve deferred.
      var directory = _urls.booksDir + model.folder + '/';
      _helper.get(directory + model.tabs[i].file).then(
        function(resData){
          _loadTrackers['book-html'] = _loadTrackers['book-html'] || 0; //make sure were not iterating null. Using extension to ensure we dont have crossover on tracking between filetypes.
          model.tabs[i].content = resData.replace(/(\r\n|\n|\r)/gm,""); //clean out returns etc.
          if(++_loadTrackers['book-html'] < model.tabs.length){
            deferred.resolve(model);
            _loadTrackers['book-html'] = 0;
          }
        }
      );
    },


    buildBookData: function(model){
      var deferred = $q.defer();
      var directory = _urls.booksDir + model.folder + '/';
      for(var i = 0; i < model.tabs.length; i++){
        _helper.preloadBookFileContents(model, i, deferred);
      }
      return deferred.promise;
    },




    buildLessonData: function(lessons){ //for now just construct a audio url...
      var deferred = $q.defer(); //using deffered for consistancy.
      for (var i = 0; i < lessons.length; i++) {
        if(lessons[i].audio) lessons[i].audio = _urls.lessonAudioDir + lessons[i].audio;
      }
      deferred.resolve(lessons);
      return deferred.promise;
    },





    buildVideoData: function(videos){ //for now just construct a video url...
      var deferred = $q.defer(); //using deffered for consistancy.
      for (var i = 0; i < videos.length; i++) {
        if(videos[i].video) videos[i].video = _urls.videoDir + videos[i].video;
      }
      deferred.resolve(videos);
      return deferred.promise;
    },





    buildMatcherData: function(matcher){ //get html files for matchers
      var deferred = $q.defer();
      _helper.get( _urls.matchersDir + matcher.htmlFile).then(
        function(resData){
          matcher.html = resData;
          deferred.resolve(matcher);
        }
      );
      return deferred.promise;
    },






    buildLessonGroupingsData: function(lessonGroupings, lessons){ //generate more comprehenvsive model including titles and descriptions of inividual lessons
      var deferred = $q.defer(); //using deffered for consistancy.
      for(var i = 0; i < lessonGroupings.length; i++){
        var lessonHash = [];
        for (var ii = 0; ii < lessonGroupings[i].lessons.length; ii++){
          var lesson = _.find(lessons, function(lesson){ return lesson.id === lessonGroupings[i].lessons[ii];});
          lessonHash.push({title: lesson.title, description: lesson.description, id: lesson.id});
        }
        lessonGroupings[i].lessons = lessonHash;
      }
      deferred.resolve(lessonGroupings);
      return deferred.promise;
    },


    buildTextData: function(model){
      var deferred = $q.defer();
      var directory = _urls.textsDir;
      _helper.get(directory + model.file).then(function(resData){
        model.content = resData.replace(/(\r\n|\n|\r)/gm,"");
        deferred.resolve(model);
      });
      return deferred.promise;
    },


    buildShowHideData: function(model){
      var deferred = $q.defer();
      var directory = _urls.showHideDir;
      _helper.get(directory + model.file).then(function(resData){
        model.content = resData.replace(/(\r\n|\n|\r)/gm,"");
        deferred.resolve(model);
      });
      return deferred.promise;
    }


  };








  var DataService = { //PUBLIC:





    cache: _cache,



    changeUrlsForBrand: function(brand){
      if(_brandUrls[brand]){
        _.assign(_urls, _brandUrls[brand]);
      }
    },



    getDictionary: function(){
      if(_cache.get('dictonary')) return $q.when(_cache.get('dictionary'));
      var promise = _helper.get(_urls.dictionary);
      promise.then(function(res){_cache.put('dictionary', res)});
      return promise;
    },




    getLessonGroupings: function(){
      var deferred = $q.defer();
      var lessonGroupings, lessons;
      if(_cache.get('lessonGroupings')){
        deferred.resolve(_cache.get('lessonGroupings'));
      } else {
        _helper.get(_urls.lessonGroupings).then(function(response){

          lessonGroupings = response;

          if(_cache.get('lessons')){
            lessons = _cache.get('lessons');
            _helper.buildLessonGroupingsData(lessonGroupings, lessons).then(function(lessonGroupingsBuilt){
              _cache.put('lessonGroupings', lessonGroupingsBuilt);
              deferred.resolve(lessonGroupingsBuilt);
            }, function(error){deffered.reject(error);});
          } else {
            _helper.get(_urls.lessons).then(function(response){
              lessons = response;
              _helper.buildLessonGroupingsData(lessonGroupings, lessons).then(function(lessonGroupingsBuilt){
                _cache.put('lessonGroupings', lessonGroupingsBuilt);
                deferred.resolve(lessonGroupingsBuilt);
              });
            }, function(error){deffered.reject(error);});
          }

        }, function(error){
          deferred.reject(error);
        });
      }
      return deferred.promise;
    },






    getLesson: function(id){
      var deferred = $q.defer();
      var lesson;
      if(_cache.get('lessons')){
        lesson = _helper.getModelById(_cache.get('lessons'), id);
        deferred.resolve(lesson);
      } else {
        _helper.get(_urls.lessons).then(function(response){
          _helper.buildLessonData(response).then(function(lessonCollection){
            _cache.put('lessons', lessonCollection);
            deferred.resolve(_helper.getModelById(lessonCollection, id));
          });
        }, function(error){
          deferred.reject(error);
        });
      }
      return deferred.promise;
    },






    getMatcher: function(id){
      var deferred = $q.defer();
      var matcher;
      if(_cache.get('matchers')){
        matcher = _helper.getModelById(_cache.get('matchers'), id);
        _helper.buildMatcherData(matcher).then(function(builtMatcher){
          deferred.resolve(builtMatcher);
        });
      } else {
        _helper.get(_urls.matchers).then(function(response){
          _cache.put('matchers', response);
          matcher = _helper.getModelById(response, id);
          _helper.buildMatcherData(matcher).then(function(builtMatcher){
            deferred.resolve(builtMatcher);
          });
        }, function(error){
          deferred.reject(error);
        });
      }
      return deferred.promise;
    },







    getActivities: function(id){
      if(_cache.get('activities')) return $q.when(_cache.get('activities'));
      var promise = _helper.get(_urls.activities);
      promise.then(function(res){_cache.put('activities', res)});
      return promise;
    },






    getMatchmap: function(id){
      var deferred = $q.defer();
      var matchmap;
      if(_cache.get('matchmaps')){
        matchmap = _helper.getModelById(_cache.get('matchmaps'), id);
        deferred.resolve(matchmap);
      } else {
        _helper.get(_urls.matchMaps).then(function(response){
          _cache.put('matchmaps', response);
          matchmap = _helper.getModelById(response, id);
          deferred.resolve(matchmap);
        }, function(error){
          deferred.reject(error);
        });
      }
      return deferred.promise;
    },






    getMatchlist: function(id){
      var deferred = $q.defer();
      var matchlist;
      if(_cache.get('matchlists')){
        matchlist = _helper.getModelById(_cache.get('matchlists'), id);
        deferred.resolve(matchlist);
      } else {
        _helper.get(_urls.matchLists).then(function(response){
          _cache.put('matchlists', response);
          matchlist = _helper.getModelById(response, id);
          deferred.resolve(matchlist);
        }, function(error){
          deferred.reject(error);
        });
      }
      return deferred.promise;
    },






    getBook: function(id){
      var deferred = $q.defer();
      var book;
      if(_cache.get('books')){
        book = _helper.getModelById(_cache.get('books'), id);
        _helper.buildBookData(book).then(function(builtBook){
          deferred.resolve(builtBook);
        });
      } else {
        _helper.get(_urls.books).then(function(response){
          _cache.put('books', response);
          book = _helper.getModelById(response, id);
          _helper.buildBookData(book).then(function(builtBook){
            deferred.resolve(builtBook);
          });
        }, function(error){
          deferred.reject(error);
        });
      }
      return deferred.promise;
    },






    getVideo: function(id){
      var deferred = $q.defer();
      var video;
      if(_cache.get('videos')){
        video = _helper.getModelById(_cache.get('videos'), id);
        deferred.resolve(video);
      } else {
        _helper.get(_urls.videos).then(function(response){
          _helper.buildVideoData(response).then(function(videos){
            _cache.put('videos', videos);
            video = _helper.getModelById(videos, id);
            deferred.resolve(video);
          });
        }, function(error){
          deferred.reject(error);
        });
      }
      return deferred.promise;
    },






    getSlideshow: function(id){

      //  This is admitedly complex. We need to to all this to keep the JSON easily maintanable by the client.
      //  First we check cache, get the slideshowModel by id
      //  Then we pass it to _constructSlideshow, which gets Lesson JSON IF the slideshowModel has a lesson reference.
      //  Then, after getting the lesson by lesson reference, we pass both the slide and the lesson to _helper.buildSlideShowData,
      //  _helper.buildSlideShowData will preload data and generally assemble and prepare the slideshow model for use in the component.

      var deferred = $q.defer();
      var slideshowModel, promise;

      var _errorFn = function(error){
        deferred.reject(error)
      };

      var _constructSlideshow = function(model){ //get lesson and package up with model to pass to buildSlideShowData

        var _deferred = $q.defer();

        if(model.lesson){ //we have a lesson reference, now grab the lesson, and send it along with the slideshowModel.
          if(_cache.get('lessons')){
            var lessons = _cache.get('lessons');
            var lesson = _.find(lessons, function(lesson){return model.lesson === lesson.id});
            _helper.buildSlideShowData(slideshowModel, lesson).then(function(resModel){
              _deferred.resolve(resModel);
            }, _errorFn );
          } else {
            _helper.get(_urls.lessons).then(function(lessons){
              var lesson = _.find(lessons, function(lesson){return model.lesson === lesson.id});
              _helper.buildSlideShowData(slideshowModel, lesson).then(function(resModel){
                _deferred.resolve(resModel);
              }, _errorFn );
            }, function(error){_deferred.reject(error);});
          }
        } else { //no lesson reference.... just pass in model withot lesson
          _helper.buildSlideShowData(slideshowModel).then(function(resModel){
            _deferred.resolve(resModel);
          }, _errorFn );
        }

        return _deferred.promise;

      };

      if(_cache.get('slideshows')){ //if we have cached json use that
        slideshowModel = _helper.getModelById(_cache.get('slideshows'), id);
        if(slideshowModel){
          _constructSlideshow(slideshowModel).then(function(resModel){ //any further caching is up to this buildSlideShowData method
            deferred.resolve(resModel);
          }, _errorFn );
        } else {
          deferred.reject('No slideshow data for id "'+id+'"');
        }
      } else { //otherwise get json first
        _helper.get(_urls.slideshows).then(function(response){
          _cache.put('slideshows', response);
          slideshowModel = _helper.getModelById(response, id);
          if(slideshowModel){
            _constructSlideshow(slideshowModel).then(function(resModel){ //any further caching is up to this buildSlideShowData method
              deferred.resolve(resModel);
            }, _errorFn );
          } else {
            deferred.reject('No slideshow data for id "'+id+'"');
          }
        }, _errorFn );
      }

      return deferred.promise;
    },



    getText: function(id){
      var deferred = $q.defer();
      var text;
      if(_cache.get('texts')){
        text = _helper.getModelById(_cache.get('texts'), id);
        _helper.buildTextData(text).then(function(builtText){
          deferred.resolve(builtText);
        });
      } else {
        _helper.get(_urls.texts).then(function(response){
          _cache.put('texts', response);
          text = _helper.getModelById(response, id);
          _helper.buildTextData(text).then(function(builtText){
            deferred.resolve(builtText);
          });
        }, function(error){
          deferred.reject(error);
        });
      }
      return deferred.promise;
    },



    getShowHide: function(id){
      var deferred = $q.defer();
      var showHide;
      if(_cache.get('show-hides')){
        showHide = _helper.getModelById(_cache.get('show-hides'), id);
        _helper.buildShowHideData(showHide).then(function(builtShowHide){
          deferred.resolve(builtShowHide);
        });
      } else {
        _helper.get(_urls.showHide).then(function(response){
          _cache.put('show-hides', response);
          showHide = _helper.getModelById(response, id);
          _helper.buildShowHideData(showHide).then(function(builtShowHide){
            deferred.resolve(builtShowHide);
          });
        }, function(error){
          deferred.reject(error);
        });
      }
      return deferred.promise;
    }


  };

  return DataService;

}]);
