'use strict';

angular.module('appLogic').directive('privacy', ['$log','GenericUtils', function($log, GenericUtils){
  return {
    restrict: 'E',
    templateUrl: 'privacy/privacy.html',
    replace: true,
    scope: {},
    link: function(scope, element, attrs) {
      var _config = {};
      var _helper = {};
      var link = {
        init: function(){
          GenericUtils.setScope(link.scope, scope);
        },
        scope: {
        }
      };
      link.init();
    }
  }
}]);
