'use strict';

angular.module('appLogic').directive('matchMap', ['$log', '$window','GenericUtils', function($log, $window, GenericUtils){
  return {
    restrict: 'E',
    templateUrl: 'match-map/match-map.html',
    replace: true,
    scope: {
      model: '='
    },
    link: function(scope, element, attrs) {

      //note: most of the functionaility for this is contained within the drag-match behavior.
      
      var _config = {};

      var _el = {
        mobileContainer: element.find('.match-map__wrapper--mobile .match-map__container')
      }

      var _helper = {
        setMobileContainerHeight: function(){
          var height = angular.element($window).height() - _el.mobileContainer.offset().top;
          _el.mobileContainer.css('height', height+'px');
        }
      };

      var link = {

        init: function(){
          GenericUtils.setScope(link.scope, scope);
          link.setDefaults();
          link.bindEvents();
          _helper.setMobileContainerHeight();
        },

        setDefaults: function(){
          if(scope.model && scope.model.title) scope.mobileTitle = scope.model.title;
        },

        bindEvents: function(){

          scope.$on('matches-complete', function(e, lastMatch){
            scope.matchesComplete = true;
          });

          scope.$on('match-success', function(e, source, target){
            scope.mobileTitle = scope.model.title;
          });

          scope.$on('match-failure', function(e, source, target){
            scope.mobileTitle = scope.model.title;
          });

          angular.element($window).on('resize', _.debounce(_helper.setMobileContainerHeight, 100));

        },

        scope: {
          setCurrentTerm: function(model){
            if(!model || !model.term) return;
            scope.mobileTitle = 'Match "'+model.term+'" to location on map.';
          }
        }
      };
      link.init();
    }
  }
}]);
