'use strict';

angular.module('appLogic').directive('videoPlayer', ['$log', '$sce', 'GenericUtils', function($log, $sce, GenericUtils){
  return {
    restrict: 'E',
    templateUrl: 'video/video.html',
    replace: true,
    scope: {
      model: '='
    },
    link: function(scope, element, attrs) {
      var _config = {};
      var _helper = {};
      var link = {
        init: function(){
          GenericUtils.setScope(link.scope, scope);
        },
        scope: {
          videoUrl: function(ext){
            return $sce.trustAsResourceUrl(scope.model.video + ext);
          }
        }
      };
      link.init();
    }
  }
}]);
