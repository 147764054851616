'use strict';

angular.module('appLogic').directive('activities', ['$log','GenericUtils', function($log, GenericUtils){
  return {
    restrict: 'E',
    templateUrl: 'activities/activities.html',
    replace: true,
    scope: {
      collection: "="
    },
    link: function(scope, element, attrs) {
      var _config = {};
      var _helper = {};
      var link = {
        init: function(){
          GenericUtils.setScope(link.scope, scope);
          GenericUtils.initElement(element);
        },
        scope: {
          hasSubGroups: function(group){
            return (group && group.subGroups && group.subGroups.length);
          },
          getColumnizedItems: function(items){
            if(items && items.length > 0){
              var chunkSize = Math.ceil(items.length / 2);
              return _.chunk(items, chunkSize);
            }

          }
        }
      };
      link.init();
    }
  }
}]);
