'use strict';

angular.module('appLogic').directive('showHide', ['$log', '$rootScope','$sce', '$q', '$timeout', 'GenericUtils', 'AudioService', function($log, $rootScope, $sce, $q, $timeout, GenericUtils, AudioService){
  return {
    restrict: 'E',
    templateUrl: 'show-hide/show-hide.html',
    replace: true,
    scope: {
      model: '='
    },
    link: function(scope, element, attrs) {
      var _config = {};
      var _helper = {
        hide: function(target){
          var toggleEl = element.find('[data-show-toggle="'+target+'"]');
          var targetEls = element.find('[data-show-target="'+target+'"]');
          toggleEl.removeClass('js-showing').addClass('js-hiding');
          targetEls.removeClass('js-showing').addClass('js-hiding');
        },
        show: function(target){
          var toggleEl = element.find('[data-show-toggle="'+target+'"]');
          var targetEls = element.find('[data-show-target="'+target+'"]');
          toggleEl.removeClass('js-hiding').addClass('js-showing');
          targetEls.removeClass('js-hiding').addClass('js-showing');
        },
        hideAll: function(){
          var toggleEl = element.find('[data-show-toggle]');
          var targetEls = element.find('[data-show-target]');
          toggleEl.removeClass('js-showing').addClass('js-hiding');
          targetEls.removeClass('js-showing').addClass('js-hiding');
          element.find('.show-hide__mobile-toggle').html('SHOW');
          scope.showingAll = false;
        },
        showAll: function(){
          var toggleEl = element.find('[data-show-toggle]');
          var targetEls = element.find('[data-show-target]');
          toggleEl.removeClass('js-hiding').addClass('js-showing');
          targetEls.removeClass('js-hiding').addClass('js-showing');
          element.find('.show-hide__mobile-toggle').html('HIDE');
          scope.showingAll = true;
        },
        showPage: function(page){
          page = parseInt(page, 10);
          var pageCount = element.find('[data-slide]').length;
          if(pageCount && page >= 0 && page < pageCount){
            element.find('.show-hide__slide-state').html('Page '+ (page+1) +' of '+pageCount);
            element.find('[data-slide]').removeClass('js-current');
            element.find('[data-slide="'+page+'"]').addClass('js-current');
            scope.currentPage = page;
          }
        },
        playAudio: function(audioPath){
          var deferred = $q.defer();
          if($rootScope.isMobile){ //Mobile can't call audio.play asynchronously do to browser behavior. So we need to do things slightly different... No budget this round to refactor the AudioService.
            scope.AudioPlayer = new Audio();
            scope.AudioPlayer.src = audioPath;
            scope.AudioPlayer.play();
            setTimeout(deferred.resolve, 500); //add 500ms to all card show times on mobile
            scope.isLoadingAudio = false;
          } else {
            scope.isLoadingAudio = true;
            scope.AudioPlayer.loadAudio(audioPath).then(function(audioObj){
              scope.isLoadingAudio = false;
              scope.AudioPlayer.initAudio(audioObj);
              scope.AudioPlayer.play();
              deferred.resolve();
            });
          }
          return deferred.promise;
        }
      };
      var link = {
        init: function(){
          GenericUtils.setScope(link.scope, scope);
          link.bindEvents();
          link.checkInitialState();
          link.initAudioPlayer();
          $timeout(function(){_helper.showPage(0)}, 100);
        },
        bindEvents: function(){
          element.on('click', '[data-show-toggle]', function(e){
            var el = angular.element(e.currentTarget);
            var target = el.attr('data-show-toggle');
            var targetEls = element.find('[data-show-target="'+target+'"]');
            var timeout = targetEls.attr('data-show-timeout');
            var audioPath = targetEls.attr('data-show-audio');
            if(el.hasClass('js-showing')){
              _helper.hide(target);
            } else {
              if(audioPath && scope.model.hasAudio){
                var audioPlaying = _helper.playAudio(audioPath);
              }
              if(timeout){
                _helper.hideAll();
                if(audioPlaying){ //deferred starting the timeout until audio has finished playing
                  audioPlaying.then(function(){
                    setTimeout(function(){
                      _helper.hide(target);
                    }, parseInt(timeout, 10) * 1000);
                  });
                }
              }
              _helper.show(target);
            }
          });
          element.on('click', '.show-hide__mobile-toggle', function(e){
            if(scope.showingAll){
              _helper.hideAll();
            }
            else {
              _helper.showAll();
            }
          });
          element.on('click', '.show-hide__slide-prev', function(e){
            if(typeof scope.currentPage !== 'undefined'){
              _helper.showPage(scope.currentPage - 1);
            }
          });
          element.on('click', '.show-hide__slide-next', function(e){
            if(typeof scope.currentPage !== 'undefined'){
              _helper.showPage(scope.currentPage + 1);
            }
          });
        },
        checkInitialState: function(){
          if(scope.model.show){
            $timeout(_helper.showAll);
          } else {
            $timeout(_helper.hideAll);
          }
        },
        initAudioPlayer: function(){
          if(scope.model && scope.model.hasAudio){
            scope.AudioPlayer = AudioService.makePlayer();
          }
        },
        scope: {
          getContent: function(){
            if(!scope.model || !scope.model.content) return false;
            return $sce.trustAsHtml(scope.model.content);
          }
        }
      };
      link.init();
    }
  }
}]);
