'use strict';
angular.module('appLogic').controller('AppCtrl', ['$scope', '$route', '$location', '$q', '$log', '$timeout', '$rootScope', 'GenericUtils', 'DataService', 'ModalService', 'DictionaryService', function($scope, $route, $location, $q, $log, $timeout, $rootScope, GenericUtils, DataService, ModalService, DictionaryService){


  var _config = {};


  var _helper = {

    handleRouteChange: function(){ //route has changed... do stuff.
      ModalService.closeAllModals($scope);
      DictionaryService.closeAllDictionaries($scope);
      $timeout(function(){$(window).scrollTop(0)}, 50);
      if(!$route.current || !$route.current.params){
        $timeout(function(){$scope.data = {}});
        return false;
      }

      var params = $route.current.params;

      if(!$location.path() || $location.path() === '/'){
        $timeout(function(){$scope.data = {}});
      } else {
        //pages
        if(params['pageid']){
          if(params['pageid'] === 'home') $timeout(function(){$scope.data = {}});
          if(params['pageid'] === 'privacy') $timeout(function(){$scope.data = {privacy:true}});
          if(params['pageid'] === 'copyright') $timeout(function(){$scope.data = {copyright:true}});
          if(params['pageid'] === 'contact') $timeout(function(){$scope.data = {contact:true}});
          if(params['pageid'] === 'order') $timeout(function(){$scope.data = {order:true}});
          if(params['pageid'] === 'lessons') _helper.loadData('lessonGroupings');
          if(params['pageid'] === 'activities') _helper.loadData('activities');
          if(params['pageid'] === 'glossary'){
            if($scope.dictionary) $timeout(function(){
              $scope.data = {glossary: $scope.dictionary}
            });
            else _helper.loadData('glossary');
          }
        }

        //individual modules
        else {
          if(params['lessonid']) _helper.loadData('lesson', params['lessonid']);
          else if(params['slideshowid']) _helper.loadData('slideshow', params['slideshowid']);
          else if(params['matchmapid']) _helper.loadData('matchmap', params['matchmapid']);
          else if(params['matchlistid']) _helper.loadData('matchlist', params['matchlistid']);
          else if(params['matcherid']) _helper.loadData('matcher', params['matcherid']);
          else if(params['videoid']) _helper.loadData('video', params['videoid']);
          else if(params['bookid']) _helper.loadData('book', params['bookid']);
          else if(params['textid']) _helper.loadData('text', params['textid']);
          else if(params['showhideid']) _helper.loadData('show-hide', params['showhideid']);
        }
      }

    },


    loadData: function(type, id){
      //load data(probably from route change) into $scope.data, the app view will handle the logic based on this data for displaying correct component
      $scope.data = null; //Whatever is loaded into this object will determine what is displayed in the main app view. This is an alternative to ng-views becuase I dont like them in this context.
      $timeout(function(){
        switch(type){
          case 'lesson':
            DataService.getLesson(id).then(function(resData){ $scope.data = { lesson: resData } });
            break;
          case 'lessonGroupings':
            DataService.getLessonGroupings().then(function(resData){ $scope.data = { lessonGroupings: resData } });
            break;
          case 'glossary':
            DataService.getDictionary().then(function(resData){ $scope.data = { glossary: resData } });
            break;
          case 'activities':
            DataService.getActivities().then(function(resData){ $scope.data = { activities: resData } });
            break;
          case 'slideshow':
            DataService.getSlideshow(id).then(function(resData){ $scope.data = { slideshow: resData } });
            break;
          case 'matchmap':
            DataService.getMatchmap(id).then(function(resData){ $scope.data = { matchmap: resData } });
            break;
          case 'matchlist':
            DataService.getMatchlist(id).then(function(resData){ $scope.data = { matchlist: resData } });
            break;
          case 'matcher':
            DataService.getMatcher(id).then(function(resData){ $scope.data = { matcher: resData } });
            break;
          case 'video':
            DataService.getVideo(id).then(function(resData){ $scope.data = { video: resData } });
            break;
          case 'book':
            DataService.getBook(id).then(function(resData){ $scope.data = { book: resData } });
            break;
          case 'text':
            DataService.getText(id).then(function(resData){ $scope.data = { text: resData } });
            break;
          case 'show-hide':
            DataService.getShowHide(id).then(function(resData){ $scope.data = { showHide: resData } });
            break;
        }
      });
    }
  };


  var controller = {
    init: function(){
      GenericUtils.setScope(controller.scope, $scope);
      controller.setWatches();

      if($rootScope.brand) DataService.changeUrlsForBrand($rootScope.brand);

      ModalService.startListening($scope); //Listen for modal events on this scope.
      DictionaryService.preloadDictionary($scope); //We should preload the entire dictionary right when the app is initialized.
      DictionaryService.startListening($scope); //Listen for dictionary events on this scope.
    },

    setWatches: function(){
      $scope.$on('$routeChangeSuccess', _helper.handleRouteChange);
    },

    scope: {
      data: {},
      shouldShowHome: function(){ //TODO: Make this better. This is admitedly not the best way to handle views.
        return (!$location.path() || $location.path() === '/' || $location.path() === '/home');
      }
    }
  };

  controller.init();

}]);
