'use strict';

angular.module('appLogic').directive('glossary', ['$log', '$sce','GenericUtils', function($log, $sce, GenericUtils){
  return {
    restrict: 'E',
    templateUrl: 'glossary/glossary.html',
    replace: true,
    scope: {
      collection: "="
    },
    link: function(scope, element, attrs) {

      var _config = {};
      var _helper = {};
      var link = {

        init: function(){
          GenericUtils.setScope(link.scope, scope);
          GenericUtils.initElement(element);
          link.getItems();
          link.setupStickyIndex();
        },

        getItems: function(){
          if(!scope.collection && scope.collection.length) return;
          var groups = [];
          _.each(scope.collection, function(model){
            var firstLetter = model.term.charAt(0).toLowerCase();
            groups.push(firstLetter);
            groups.sort();
            groups = _.uniq(groups);
          });
          for (var i = 0; i < groups.length; i++) {
            var items = _.filter(scope.collection, function(model){return model.term.charAt(0).toLowerCase() === groups[i]});
            groups[i] = {name:groups[i], items: items};
          }
          scope.items = groups;
        },

        setupStickyIndex: function(){
          var indexEl = element.find('.glossary__index');
          var indexTop = indexEl.offset().top;
          var winEl = angular.element(window);
          winEl.on('scroll', _.throttle(function(){
            if(winEl.scrollTop() >= indexTop) element.addClass('js-sticky');
            else element.removeClass('js-sticky');
          }, 20));
        },

        scope: {

          getHtml: function(stuff){
            return $sce.trustAsHtml(stuff);
          },

          goTo: function(e, key){
            e.preventDefault();
            var elOfInterest = element.find('[data-key="'+key+'"]');
            var indexHeight = element.find('.glossary__index').height();
            var elOffset = Math.floor(elOfInterest.offset().top - (indexHeight + 20));
            angular.element(window).scrollTop(elOffset);
          }

        },

      };
      link.init();
    }
  }
}]);
