'use strict';

var _config = {
  mobileBp: 960
}

var _helper = {
  getUrlParam: function(name) {
    var url = location.href;
    name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
    var regexS = "[\\?&]"+name+"=([^&#]*)";
    var regex = new RegExp( regexS );
    var results = regex.exec( url );
    return results == null ? null : results[1];
  }
}

angular.module('angular-bind-html-compile', []).directive('bindHtmlCompile', ['$compile', function ($compile) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            scope.$watch(function () {
                return scope.$eval(attrs.bindHtmlCompile);
            }, function (value) {
                element.html(value);
                $compile(element.contents())(scope);
            });
        }
    };
}]);

angular.module('appLogic', ['ngRoute', 'templates', 'angular-bind-html-compile'])
  .config(['$routeProvider', '$compileProvider', function($routeProvider, $compileProvider){
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|chrome-extension|modal|dict):/);
    $routeProvider
      .when('/:pageid', {controller: 'AppCtrl'})
      .when('/lesson/:lessonid', {controller: 'AppCtrl'})
      .when('/slideshow/:slideshowid', {controller: 'AppCtrl'})
      .when('/matchmap/:matchmapid', {controller: 'AppCtrl'})
      .when('/matchlist/:matchlistid', {controller: 'AppCtrl'})
      .when('/matcher/:matcherid', {controller: 'AppCtrl'})
      .when('/video/:videoid', {controller: 'AppCtrl'})
      .when('/book/:bookid', {controller: 'AppCtrl'})
      .when('/text/:textid', {controller: 'AppCtrl'})
      .when('/show-hide/:showhideid', {controller: 'AppCtrl'})
  }])
  .run(['$rootScope', '$cacheFactory', '$window', function($rootScope, $cacheFactory, $window){
    $cacheFactory('dataCache');

    //set sub-domain version (some content within the app is toggled depending on the sub-domain that the app is viewed through)
    var subDomain = location.hostname.split('.')[0].toLowerCase();
    switch(subDomain){
      case 'odbcu': $rootScope.brand = 'odbcu'; break;
      default: $rootScope.brand = 'default'; break;
    }

    //so we can test brand changes with url param 'brandprev'
    var brandPreview = _helper.getUrlParam('brandprev');
    if(brandPreview) $rootScope.brand = brandPreview;

    //CHECK MOBILE
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      $rootScope.isMobile = true;
    }

  }]);
