'use strict';
angular.module('appLogic').service('AudioService', ['$log', '$timeout', '$q', '$rootScope', function($log, $timeout, $q, $rootScope){

  var _loadedAudio = {};

  var _helper = {

    updateAudioProgress: function(){
      if( !this.els.trackEl || !this.els.timeEl ) return false;
      var audioObj = this.currentAudio;
      var percentage = (audioObj.currentTime / audioObj.duration) * 100;
      this.els.trackEl.css({'width': percentage+'%'});
      this.els.timeEl.html(_helper.getAudioTime(audioObj));
      if(this.els.handleEl && !this.draggingHandle){
        this.els.handleEl.css({'left': percentage+'%'});
      }
    },

    startTrackingAudio: function(){ //update the tracking bar and the time indicator on interval
      var _this = this;
      if(this.audioTrackerTimer) this.stopTrackingAudio.call(this);
      this.audioTrackerTimer = setInterval(function(){
        _helper.updateAudioProgress.call(_this);
      }, 200);
    },

    stopTrackingAudio: function(){
      clearInterval(this.audioTrackerTimer);
      this.audioTrackerTimer = null;
    },

    setupDragHandle: function(){

      if(!this.els.handleEl || !this.els.trackEl) return false;


      var _this = this;

      var _findPositionPercent = function(xPos){
        var barOffset = _this.els.trackEl.offset().left;
        var barWidth = _this.els.trackEl.parent()[0].clientWidth;
        return Math.max(0, Math.min(1, (xPos - barOffset) / barWidth));
      };

      this.els.handleEl.on('mousedown', function(e){

        _this.draggingHandle = true;

        e.preventDefault();

        $(document).on('mousemove.audioControl', _.throttle(function(e){
          var pos = _findPositionPercent(e.pageX);
          _this.els.handleEl.css({'left': (pos*100)+'%'});
        }, 10));

        $(document).on('mouseup.audioControl', function(e){
          var pos = _findPositionPercent(e.pageX);
          _this.goToPosition(pos);
          $(document).off('mousemove.audioControl');
          $(document).off('mouseup.audioControl');
          _this.draggingHandle = false;
        });

      });

    },

    getAudioTime: function(audioObj){
      if (!audioObj) return null;

      var _addZeros = function(nStr){
        if(nStr.length === 1) return '0' + nStr;
        else return nStr;
      };

      var cMins = Math.floor(audioObj.currentTime/60).toString(),
          cSecs = _addZeros(Math.floor(audioObj.currentTime % 60).toString()),
          dMins = Math.floor(audioObj.duration/60).toString(),
          dSecs = _addZeros(Math.floor(audioObj.duration % 60).toString());

      return cMins + ':' + cSecs + '/' + dMins + ':' + dSecs;
    }

  };



  //Player Class:   ----------------------------

  var _AudioPlayer = function(timeEl, trackEl, handleEl){

    this.els = {};
    this.loadedAudio = _loadedAudio;
    this.currentAudio = null;
    this.audioTrackerTimer = null;
    this.loadChecker = null; //load checking timer holder
    this.isPlaying = false;


    //ternairies are here to ensure we arent assigning empty jquery arrays.
    this.els.timeEl = (timeEl && timeEl.length)  ? timeEl : undefined;
    this.els.trackEl = (trackEl && trackEl.length) ? trackEl : undefined;
    this.els.handleEl = (handleEl && handleEl.length) ? handleEl : undefined;


  };

    _AudioPlayer.prototype.loadAudio = function(path){
      var _this = this;
      if(!path) return false;
      var deferred = $q.defer();
      if(this.loadedAudio[path]){
        deferred.resolve(this.loadedAudio[path]);
      } else {
        var audioObj = new Audio(path); //possible leak?
        audioObj.preload = 'auto';
        audioObj.controls = 'false';
        if($rootScope.isMobile){
          deferred.resolve(audioObj);
        } else {
          if(_this.loadChecker) clearInterval(_this.loadChecker); //Make sure we dont create a memory leak and bugs...
          _this.loadChecker = setInterval(function(){
            if(audioObj.readyState >= 4) {
              clearInterval(_this.loadChecker);
              _this.loadChecker = null;
              _this.loadedAudio[path] = audioObj;
              _this.loadChecker = null;
              deferred.resolve(audioObj);
            }
          }, 350);
        }
      }
      return deferred.promise;
    };


    _AudioPlayer.prototype.initAudio = function(audioObj){
      if(!audioObj) return false;
      if(this.currentAudio) this.kill();
      this.currentAudio = audioObj;
      _helper.startTrackingAudio.call(this);
      _helper.setupDragHandle.call(this);
      audioObj.onended = function(){
        $rootScope.$broadcast('audio-finished-playing');
      }
    };


    _AudioPlayer.prototype.kill = function(audioObj){
      if(!this.currentAudio) return;
      this.currentAudio.pause();
      this.isPlaying = false;
      this.currentAudio.currentTime = 0;
      _helper.updateAudioProgress.call(this); //make sure trackers reflect zeroed time.
      _helper.stopTrackingAudio.call(this);
      if(this.els.handelEl && this.els.handelEl.length) this.els.handleEl.off('mousedown');
      if(this.els.trackEl && this.els.trackEl.length) this.els.trackEl.off('click');
      this.currentAudio = null;
      if(this.loadChecker) clearInterval(this.loadChecker);
    };

    _AudioPlayer.prototype.goToPosition = function(position){ // go to position as a percentage (0-1)
      position = Math.min(1,Math.max(0, position)); //make sure we arent out of bounds somehow.
      this.currentAudio.currentTime = (this.currentAudio.duration * position);
    };

    _AudioPlayer.prototype.play = function(){
      if(!this.currentAudio) return;
      this.currentAudio.play();
      this.isPlaying = true;
    };


    _AudioPlayer.prototype.pause = function(){
      if(!this.currentAudio) return;
      this.currentAudio.pause();
      this.isPlaying = false;
    };


    _AudioPlayer.prototype.restart = function(){
      if(!this.currentAudio) return;
      this.currentAudio.currentTime = 0;
    };


    _AudioPlayer.prototype.stop = function(){
      if(!this.currentAudio) return;
      this.pause();
      this.restart();
    };

    _AudioPlayer.prototype.destroy = function(){
      this.kill();
    };


  var AudioService = {

    players: [],


    makePlayer: function(timeEl, trackEl, handleEl){ //Pass in elements updated by this services.
      var Player = new _AudioPlayer(timeEl, trackEl, handleEl);
      AudioService.players.push(Player);
      return Player;
    },


    pauseAllPlayers: function(){
      _.each(AudioService.players, function(Player){
        Player.pause();
      });
    },


    stopAllPlayers: function(){
      _.each(AudioService.players, function(Player){
        Player.stop();
      });
    },


    killAllPlayers: function(){
      _.each(AudioService.players, function(Player){
        Player.stop();
        Player.kill();
      });
    },


    destroyPlayer: function(PlayerToDestroy){
      PlayerToDestroy.stop();
      PlayerToDestroy.kill();
      _.remove(AudioService.players, function(Player){
        return Player === PlayerToDestroy;
      });
    },


    destroyAllPlayers: function(){
      _.each(AudioService.players, function(Player){
        Player.stop();
        Player.kill();
      });
      AudioService.players = null; //This should allow garbage collection?
    }


  };

  return AudioService;

}]);
