'use strict';

angular.module('appLogic').service('GenericUtils', ['$log', '$timeout', function($log, $timeout){
  return {
    setScope: function(scopeSrc, scopeDest){
      for (var key in scopeSrc){
        scopeDest[key] = scopeSrc[key];
      }
    },
    initElement: function(element){
      $timeout(function(){element.addClass('js-init')}, 20); //Using this for triggering transitions. Easy and very predictable.
    }
  };
}]);
