'use strict';

angular.module('appLogic').service('ModalService', ['$q', '$compile', '$log', 'DataService', function($q, $compile, $log, DataService){


  var _config = {
    parentEl: 'body'
  };

  var _helper = {

    getModalData: function(type, id){
      var deffered = $q.defer();
      var _resolve = function(resData){ deffered.resolve(resData) },
          _reject = function(error){ deffered.reject(error) };

      switch(type){
        case 'lesson': DataService.getLesson(id).then(_resolve, _reject); break;
        case 'slideshow': DataService.getSlideshow(id).then(_resolve, _reject); break;
        case 'matchmap': DataService.getMatchmap(id).then(_resolve, _reject); break;
        case 'matchlist': DataService.getMatchlist(id).then(_resolve, _reject); break;
        case 'matcher': DataService.getMatcher(id).then(_resolve, _reject); break;
        case 'video': DataService.getVideo(id).then(_resolve, _reject); break;
        case 'book': DataService.getBook(id).then(_resolve, _reject); break;
        case 'text': DataService.getText(id).then(_resolve, _reject); break;
        case 'show-hide': DataService.getShowHide(id).then(_resolve, _reject); break;
      }

      return deffered.promise;
    },

    closeAllModals: function(scope){
      angular.element(_config.parentEl).find('.modal').remove();
      scope.modalData = null;
    },

    launchModal: function(options, scope){
      _helper.closeAllModals(scope);
      //angular.element(window).scrollTop(0);
      options.coords = options.coords || [0,0];
      var parentEl = angular.element(_config.parentEl);
      _helper.getModalData(options.type, options.id).then(function(data){
        scope.modalData = data;
        var modalEl = $compile('<modal type="'+options.type+'" data="modalData" anchor-launcher></modal>')(scope);
        parentEl.append(modalEl);
      });
    }
  };

  return {

    startListening: function(scope){
      scope.$on('launch-modal', function(e, options){_helper.launchModal(options, scope)});
    },
    closeAllModals: _helper.closeAllModals

  };
}]);
