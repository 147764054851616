'use strict';

angular.module('appLogic').directive('home', ['$log', '$window', '$timeout', 'GenericUtils', function($log, $window, $timeout, GenericUtils){
  return {
    restrict: 'E',
    templateUrl: 'home/home.html',
    replace: true,
    scope: {},
    link: function(scope, element, attrs) {
      var _config = {};

      var _helper = {

        setCurrentSlide: function(index){ //this helper method ensures current slide is set within bounds
          if(index >= scope.slideCount) index = 0;
          else if(index < 0 ) index = scope.slideCount - 1;
          scope.currentSlide = index;
        },

        slideTo: function(index){ //Do the actual transform. Animation is set as a transition in CSS
          var sBoard = element.find('.home__carousel-items');
          var tVal = scope.slideWidth * index * -1;
          var tStr = 'translate3d('+tVal+'px, 0px, 0px)';
          var ctStr = 'translate('+tVal+'px, 0px)'; //IE9 compatability
          sBoard[0].style.webkitTransform = tStr;
          sBoard[0].style.MozTransform = tStr;
          sBoard[0].style.msTransform = ctStr; //Hooray IE!
          sBoard[0].style.OTransform = tStr;
          sBoard[0].style.transform = tStr;
        }

      };

      var link = {

        init: function(){
          GenericUtils.setScope(link.scope, scope);
          link.setDefaults();
          link.setWatches();
          link.bindEvents();
          link.setWidths();
          GenericUtils.initElement(element);
        },


        setDefaults: function(){
          scope.currentSlide = 0;
          scope.slideCount = element.find('.home__carousel-item').length;
        },


        setWatches: function(){

          scope.$watch('currentSlide', function(newVal, oldVal){
            _helper.slideTo(newVal);
          });

        },


        bindEvents: function(){

          angular.element($window).on('resize', _.debounce(function(){
            scope.$apply(function(){
              link.setWidths();
              $timeout(function(){_helper.slideTo(scope.currentSlide)});
            });
          }, 100));

        },


        setWidths: function(){
          scope.slideWidth = element.find('.home__carousel-slider').width(); //styles are set with this in the template on digest.
        },

        //--------------

        scope: {

          nextSlide: function(e){
            e.preventDefault();
            _helper.setCurrentSlide(scope.currentSlide + 1);
          },

          prevSlide: function(e){
            e.preventDefault();
            _helper.setCurrentSlide(scope.currentSlide - 1);
          }

        }
      };

      link.init();
    }
  }
}]);
