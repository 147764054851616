'use strict';

angular.module('appLogic').directive('slideshow', ['$rootScope','$log', '$sce', '$window', '$timeout', '$interval', 'GenericUtils', 'AudioService', function($rootScope, $log, $sce, $window, $timeout, $interval, GenericUtils, AudioService){
  return {
    restrict: 'E',
    templateUrl: 'slideshow/slideshow.html',
    replace: true,
    scope: {
      model: '='
    },
    link: function(scope, element, attrs) {


      var _config = {};


      var _els = {
        audioTrack: element.find('.slideshow__audio-track-progress'),
        audioHandle: element.find('.slideshow__audio-track-handle'),
        audioTime: element.find('.slideshow__audio-time')
      }



      var _helper = {



        //SLIDE STUFF: -------------------

        getSlideArray: function(model){ //transforming some of the data to be digestible as needed by ng-repeat
          if(!model) return;
          var slideArray = [];
          for(var i = 0; i < model.slideCount; i++){
            slideArray[i] = {
              html: (model.html && model.html.length) ? $sce.trustAsHtml(model.html[i]) : null,
              image: (model.images && model.images.length) ? model.images[i] : null,
              txt: (model.txt && model.txt.length) ? $sce.trustAsHtml(model.txt[i]) : null,
            }
          }
          return slideArray;
        },


        setCurrentSlide: function(index){ //this helper method ensures current slide is set within bounds
          if(index >= scope.model.slideCount || index < 0 ) return false;
          scope.currentSlide = index;
        },


        slideTo: function(index){ //Do the actual transform. Animation is set as a transition in CSS
          var sBoard = element.find('.slideshow__slides');
          var tVal = scope.slideWidth * index * -1;
          var tStr = 'translate3d('+tVal+'px, 0px, 0px)';
          var ctStr = 'translate('+tVal+'px, 0px)'; //IE9 compatability
          sBoard[0].style.webkitTransform = tStr;
          sBoard[0].style.MozTransform = tStr;
          sBoard[0].style.msTransform = ctStr; //Hooray IE!
          sBoard[0].style.OTransform = tStr;
          sBoard[0].style.transform = tStr;
        },



        //AUDIO STUFF: -------------------

        loadSlideAudio: function(slideIndex){
          if(!scope.AudioPlayer || !scope.model.audio || !scope.model.audio[slideIndex]) return false;
          _helper.stopAudio();
          var path = scope.model.audio[slideIndex];
          scope.loadedAudio = scope.loadedAudio || [];
          scope.isLoadingAudio = true;
        scope.AudioPlayer.loadAudio(path).then(function(audioObj){
            if(!scope.AudioPlayer) return;
            scope.isLoadingAudio = false;
            scope.loadedAudio[slideIndex] = audioObj;
            scope.AudioPlayer.initAudio(audioObj);
            if(!scope.hasPaused && !$rootScope.isMobile) _helper.playAudio();
          });
        },

        playAudio: function(){
          if(!scope.AudioPlayer || scope.isLoadingAudio) return false;
          scope.AudioPlayer.play();
          scope.hasPaused = false;
          scope.isPlayingAudio = true;
        },

        pauseAudio: function(){
          if(!scope.AudioPlayer || scope.isLoadingAudio) return false;
          scope.AudioPlayer.pause();
          scope.hasPaused = true;
          scope.isPlayingAudio = false;
        },

        stopAudio: function(){
          if(!scope.AudioPlayer || scope.isLoadingAudio) return false;
          scope.AudioPlayer.stop();
          scope.isPlayingAudio = false;
        },

        restartAudio: function(){
          if(!scope.AudioPlayer || scope.isLoadingAudio) return false;
          scope.AudioPlayer.restart();
        }

      };



      //-------------------------



      var link = {


        init: function(){
          GenericUtils.setScope(link.scope, scope);
          link.setDefaults();
          link.setWatches();
          link.initAudioPlayer();
          link.bindEvents();

          if(scope.model.class) element.addClass('slideshow--'+scope.model.class);

          GenericUtils.initElement(element);
        },


        setDefaults: function(){
          scope.currentSlide = 0;
        },


        setWatches: function(){

          scope.$watch('model', function(newVal, oldVal){
            scope.slides = _helper.getSlideArray(newVal);
            link.setWidths();
          });

          scope.$watch('currentSlide', function(newVal, oldVal){
            _helper.slideTo(newVal);
            _helper.loadSlideAudio(newVal);
          });

        },


        bindEvents: function(){

          angular.element($window).on('resize', _.debounce(function(){
            scope.$apply(function(){
              link.setWidths();
              $timeout(function(){_helper.slideTo(scope.currentSlide)});
            });
          }, 100));

          //debouncing due to a strange safari 9.1 bug which sends 2 audio end events
          scope.$on('audio-finished-playing', _.debounce(function(){
            scope.$apply(function(){
              scope.isPlayingAudio = false;
              _helper.setCurrentSlide(scope.currentSlide + 1);
            });
          }, 500, {
            'leading': true,
            'trailing': false
          }));

          scope.$on('$destroy', function(){
            if(scope.AudioPlayer){
              AudioService.destroyPlayer(scope.AudioPlayer);
              scope.AudioPlayer = null;
            }
          });


        },


        setWidths: function(){
          scope.slideWidth = element.find('.slideshow__slide-container').width(); //styles are set with this in the template on digest.
        },

        initAudioPlayer: function(){
          if(!scope.model.hasAudio) return false;
          scope.AudioPlayer = AudioService.makePlayer(_els.audioTime, _els.audioTrack, _els.audioHandle);
        },

        //--------------

        scope: {

          shouldShowControls: function(){
            return (scope.model && scope.model.slideCount && scope.model.slideCount > 1) || scope.model.hasAudio;
          },

          currentSlideText: function(){
            if(!scope.slides || scope.slides.length) return false;
            if(scope.slides[scope.currentSlide] && scope.slides[scope.currentSlide].txt){
              return scope.slides[scope.currentSlide].txt;
            }
          },

          shouldShowSlides: function(){
            return (scope.model && scope.model.slideCount && scope.model.slideCount > 1);
          },

          nextSlide: function(e){
            e.preventDefault();
            _helper.setCurrentSlide(scope.currentSlide + 1);
          },

          prevSlide: function(e){
            e.preventDefault();
            _helper.setCurrentSlide(scope.currentSlide - 1);
          },

          closeSlideshow: function(e){
            e.preventDefault();
            window.history.back();
          },

          playAudio: _helper.playAudio,
          pauseAudio: _helper.pauseAudio,
          restartAudio: _helper.restartAudio,
          stopAudio: _helper.stopAudio

        }

      };

      link.init();

    }
  }
}]);
