'use strict';

angular.module('appLogic').directive('text', ['$log', '$sce','GenericUtils', function($log, $sce, GenericUtils){
  return {
    restrict: 'E',
    templateUrl: 'text/text.html',
    replace: true,
    scope: {
      model: '='
    },
    link: function(scope, element, attrs) {
      var _config = {};
      var _helper = {};
      var link = {
        init: function(){
          GenericUtils.setScope(link.scope, scope);
          console.log(scope.model.class);
        },
        scope: {
          getContent: function(){
            if(!scope.model || !scope.model.content) return false;
            return $sce.trustAsHtml(scope.model.content);
          }
        }
      };
      link.init();
    }
  }
}]);
