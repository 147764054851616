'use strict';

angular.module('appLogic').directive('dragMatch', ['$log', '$timeout', function($log, $timeout){
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {

      //rather than polute scope keep all the tracking in these here variables here:
      var _currentSource = null;
      var _currentMobileSource = null;
      var _currentTarget = null;
      var _currentMobileTarget = null;
      var _draggingEl = null;
      var _sourceEl = null;
      var _matchedEls = 0;



      var _config = {
        containerEl: 'body',
      }

      var _els = {
        desktopMatcher: element.find('[drag-matcher="desktop"]'),
        mobileMatcher: element.find('[drag-matcher="mobile"]')
      }

      var _helper = {

        //Desktop Matching behavior helpers:
        moveDraggedEl: function(x, y){

          if(!_draggingEl) return;

          var tStr = 'translate3d('+x+'px,'+y+'px,0px)';
          var ctStr = 'translate('+x+'px,'+y+'px)'; //IE9 compatability

          _draggingEl[0].style.webkitTransform = tStr;
          _draggingEl[0].style.MozTransform = tStr;
          _draggingEl[0].style.msTransform = ctStr; //Hooray IE!
          _draggingEl[0].style.OTransform = tStr;
          _draggingEl[0].style.transform = tStr;

        },



        startDrag: function(event, el){

          // When we start drag, we add a class to the source element,
          // and then clone the element into a fixed position element (dragEl) attached to the containerEl that follows the mouse around.
          // This is an easy, predictable way to 'fake' dragging, and allows for maximum flexibility of design and layout.

          _currentSource = el.attr('match-source');

          var dragEl = angular.element('<div></div>');

          element.find('.js-unmatched').removeClass('js-unmatched');

          dragEl
            .attr('class', el.attr('class'))
            .addClass('js-drag-el')
            .css({
              'width': el.outerWidth() + 'px',
              'height': el.outerHeight() + 'px',
              'position':'fixed',
              'left': '10px',
              'top': '10px',
              'margin': '0',
              'pointer-events': 'none',
              'z-index': 10000
            })
            .html(el.html())
            .appendTo(_config.containerEl);

          el.addClass('js-dragging');

          _sourceEl = el;
          _draggingEl = dragEl;

          _helper.moveDraggedEl(event.pageX, event.pageY);

          scope.$apply( function(){ scope.$broadcast('drag-started') } );

          element.addClass('js-drag-active'); //Top level parent element class

        },



        endDrag: function(){

          if(_currentSource && _currentTarget && _currentSource === _currentTarget){
            scope.$apply(function(){scope.$broadcast('match-success', _currentSource)});
            element.removeClass('js-mobile-matching');
          } else if(_currentSource && _currentTarget) {
            scope.$apply(function(){scope.$broadcast('match-failure', _currentSource, _currentTarget)});
            element.removeClass('js-mobile-matching');
          }
          if(_draggingEl){
            _draggingEl.remove();
            _draggingEl = null;
          }
          if(_sourceEl){
            _sourceEl.removeClass('js-dragging');
            _sourceEl = null;
          }
          _currentSource = null;
          _currentTarget = null;

          element.removeClass('js-drag-active');
          scope.$apply(function(){scope.$broadcast('drag-ended')});

        },

        setTarget: function(el){
          el.addClass('js-targeted');
          _currentTarget = el.attr('match-target');
        },

        clearTargets: function(el){
          el.removeClass('js-targeted');
          _currentTarget = null;
        },
        //Mobile Matching behavior helpers:
        startMobileMatch: function(source){
          _currentMobileSource = source.attr('match-source');
          element.addClass('js-mobile-matching');
        },

        endMobileMatch: function(target){
          _currentMobileTarget = target.attr('match-target');
          console.log(_currentMobileSource, _currentMobileTarget);
          if(_currentMobileSource && _currentMobileTarget && _currentMobileSource === _currentMobileTarget){
            scope.$apply(function(){scope.$broadcast('match-success', _currentMobileSource)});
          } else if(_currentMobileSource && _currentMobileTarget) {
            scope.$apply(function(){scope.$broadcast('match-failure', _currentMobileSource, _currentMobileTarget)});
          }
          element.removeClass('js-mobile-matching');
        }


      };


      var link = {


        init: function(){
          $timeout(function(){link.setupDrag()}, 100);
        },


        setupDrag: function(){

          //two differnet behaviors for mobile and desktop so grab 2 diffent sets of elements for event binding:
          var mSourcesDesktop = _els.desktopMatcher.find('[match-source]');
          var mTargetsDesktop = _els.desktopMatcher.find('[match-target]');

          var mSourcesMobile = _els.mobileMatcher.find('[match-source]');
          var mTargetsMobile = _els.mobileMatcher.find('[match-target]');


          var totalMatches = mSourcesDesktop.length || mSourcesMobile.length; //so we know how many matches to count to send completed event. TODO: Make this not based on element count.

          // Dispatch events that our host component might be concerned with:
          scope.$on('match-success', function(e, matchedSource){
            element.find('[match-source="'+matchedSource+'"], [match-target="'+matchedSource+'"]')
              .removeClass('js-unmatched')
              .addClass('js-matched');
            if(++_matchedEls >= totalMatches){
              scope.$broadcast('matches-complete', matchedSource); //sending along last matched source in case we want cool effects or something
            }
          });


          scope.$on('match-failure', function(e, matchedSource, matchedTarget){

            var el = element.find('[match-source="'+matchedSource+'"], [match-target="'+matchedTarget+'"]')
              .addClass('js-unmatched');

            setTimeout(function(){el.removeClass('js-unmatched')}, 1000);

          });


          if(mSourcesDesktop.length){
            //Desktop events (drag & drop):
            mSourcesDesktop.on('mousedown', function(e){
              e.preventDefault();
              if(angular.element(e.currentTarget).hasClass('js-matched')) return;
              _helper.startDrag(e, angular.element(e.currentTarget));
            });

            mTargetsDesktop.on('mouseenter', function(e){
              _helper.setTarget(angular.element(e.currentTarget));
            });

            mTargetsDesktop.on('mouseleave', function(e){
              _helper.clearTargets(angular.element(e.currentTarget));
            });

            angular.element(_config.containerEl).on('mouseup.dragMatch', function(e){
              _helper.endDrag();
            });

            angular.element(_config.containerEl).on('mousemove.dragMatch', _.throttle(function(e){
              _helper.moveDraggedEl(e.pageX, e.pageY);
            }, 15));
          }



          if(mSourcesMobile.length){
            //Mobile events (tap to 'activate' then tap to 'match'):
            mSourcesMobile.on('mousedown touchstart', function(e){
              e.preventDefault();
              if(angular.element(e.currentTarget).hasClass('js-matched')) return;
              _helper.startMobileMatch(angular.element(e.currentTarget));
            });
            mTargetsMobile.on('mousedown touchstart', function(e){
              e.preventDefault();
              if(angular.element(e.currentTarget).hasClass('js-matched')) return;
              _helper.endMobileMatch(angular.element(e.currentTarget));
            });
          }


          //Cleanup events attached outside scope element:
          scope.$on('$destroy', function(){
            angular.element(_config.containerEl).off('mousemove.dragMatch');
            angular.element(_config.containerEl).off('mouseup.dragMatch');
          });


        }

      };

      link.init();
    }
  }
}]);
