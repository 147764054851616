'use strict';

angular.module('appLogic').service('DictionaryService', ['$q', '$compile', '$log', 'DataService', function($q, $compile, $log, DataService){


  var _config = {
    parentEl: 'html'
  };

  var _helper = {

    closeAllDictionaries: function(scope){
      angular.element(_config.parentEl).find('.dictionary').remove();
    },

    launchDictionary: function(options, scope){
      _helper.closeAllDictionaries(scope);
      options.coords = options.coords || [0,0];
      var parentEl = angular.element(_config.parentEl);
      var dictEl = $compile('<dict dictionary="dictionary" term="'+ options.term +'" coords="['+options.coords[0]+','+options.coords[1]+']" anchor-launcher></dict>')(scope);
      parentEl.append(dictEl);
    }
  };

  return {

    startListening: function(scope){
      scope.$on('launch-dictionary', function(e, options){_helper.launchDictionary(options, scope)});
    },

    preloadDictionary: function(scope){
      DataService.getDictionary().then(function(response){
        scope.dictionary = response;
      });
    },

    closeAllDictionaries: _helper.closeAllDictionaries

  };
}]);
