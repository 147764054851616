'use strict';

angular.module('appLogic').directive('contact', ['$log','GenericUtils', function($log, GenericUtils){
  return {
    restrict: 'E',
    templateUrl: 'contact/contact.html',
    replace: true,
    scope: {},
    link: function(scope, element, attrs) {
      var _config = {};
      var _helper = {};
      var link = {
        init: function(){
          GenericUtils.setScope(link.scope, scope);
          link.setupFormSubmission();
        },
        setupFormSubmission: function(){
          element.on('submit', 'form', function(e){
            var form = element.find('.contact-form');
            e.preventDefault();
            $.ajax({
                 type: 'POST',
                 url: form.attr('action'),
                 data: _.object(_.map(form.serializeArray(), _.values)),
                 dataType: "json",
                 success: function(response) {
                   scope.$apply(function(){
                     scope.hasSubmitted = true;
                   });
                 }
             });
          });
        },
        scope: {}
      };
      link.init();
    }
  }
}]);
