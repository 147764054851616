'use strict';

angular.module('appLogic').directive('matchList', ['$log','GenericUtils', function($log, GenericUtils){
  return {
    restrict: 'E',
    templateUrl: 'match-list/match-list.html',
    replace: true,
    scope: {
      model: '='
    },
    link: function(scope, element, attrs) {
      var _config = {};
      var _helper = {};
      var link = {
        init: function(){
          GenericUtils.setScope(link.scope, scope);
          link.setDefaults();
          link.bindEvents();
        },
        setDefaults: function(){
          if(scope.model && scope.model.title) scope.mobileTitle = scope.model.title;
        },
        bindEvents: function(){

          scope.$on('matches-complete', function(e, lastMatch){
            scope.matchesComplete = true;
          });

          scope.$on('match-success', function(e, source, target){
            scope.mobileTitle = scope.model.title;
          });

          scope.$on('match-failure', function(e, source, target){
            scope.mobileTitle = scope.model.title;
          });

        },
        scope: {
          setCurrentTerm: function(model){
            if(!model || !model.term) return;
            scope.mobileTitle = 'Match "' + model.term + '" to definition.';
          }
        }
      };
      link.init();
    }
  }
}]);
